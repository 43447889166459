import types from "../actionTypes";
import hubspotService from "../../services/hubspot.service";

export const getHubspotDeal =
  ({ dealId } = {}) =>
  (dispatch) => {
    dispatch({
      type: types.GET_HUBSPOT_DEAL_REQUEST
    });

    return hubspotService
      .getHubspotDeal({ dealId })
      .then((payload) => {
        dispatch({
          type: types.GET_HUBSPOT_DEAL_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_HUBSPOT_DEAL_FAILURE,
          error
        });
      });
  };
