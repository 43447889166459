import PropTypes from "prop-types";
import styled from "styled-components";
import { NewButton, Icon } from "../../../components";
import { useMobileOrTabletMediaQuery } from "../../../components/Responsive";
import AddOutcomeOverrideButton from "./AddOutcomeOverrideButton";
import {
  hasPermission,
  selectDiscoveryId,
  selectDiscoveryKPIs,
  selectDiscoveryRoiOverrides,
  selectRequestParameters,
  selectRequestState
} from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { bindActionCreators, compose } from "redux";
import { deleteDiscoveryROIOverride } from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import {
  startROIOverrideOperation,
  stopROIOverrideOperation
} from "../../../store/actions/roi-overrides-operations";
import useLoadingState from "../../../utils/use-loading-state";
import { useString as s } from "../../../components/StringProvider";
import actionTypes from "../../../store/actionTypes";
import Notification from "../../../components/Notification";
import { themeProp } from "../../../theme";

const ROIOverrides = ({
  discoveryId,
  discoveryKPIs,
  annualAdjustmentMode,
  outcomeOverrideType,
  roiOverrides,
  startROIOverrideOperation,
  stopROIOverrideOperation,
  deleteDiscoveryROIOverride,
  dataCy,
  deleteDiscoveryROIOverrideLoadingState,
  deleteDiscoveryROIOverrideRequestParameters,
  canUpdateROIValues,
  showOverrideNumber
}) => {
  const mobileOrTablet = useMobileOrTabletMediaQuery();

  const deleteSuccessMessage = s(
    "discovery.roi.overrideModal.delete.success",
    "Outcome override was removed"
  );

  const deleteFailureMessage = s(
    "discovery.roi.overrideModal.delete.failure",
    "Outcome override failed to delete"
  );

  useLoadingState(
    deleteDiscoveryROIOverrideLoadingState,
    () => {
      if (
        deleteDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.info(deleteSuccessMessage);
      }
    },
    () => {
      if (
        deleteDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.error(deleteFailureMessage);
      }
    }
  );

  const kpiOverrides = Object.entries(
    roiOverrides?.[outcomeOverrideType] || {}
  ).map(([key, value]) => ({ key, value }));

  const rioOverrideList = kpiOverrides.map(({ key, value }) => {
    const name = discoveryKPIs.find(({ kpiCode }) => kpiCode === key)
      ?.definition?.name;

    return {
      id: key,
      kpiCode: key,
      overrideValue: value,
      name
    };
  });

  return annualAdjustmentMode ? (
    <Container data-cy={dataCy}>
      <AddOutcomeOverrideButton outcomeOverrideType={outcomeOverrideType} />
      {rioOverrideList.map((item) => (
        <ButtonWrapper key={item.id}>
          <OverrideTagButton
            data-cy={`override-tag`}
            disabled={!canUpdateROIValues}
            type={"iconTextTertiary"}
            onClick={() => {
              startROIOverrideOperation({
                kpiCode: item.kpiCode,
                outcomeOverrideType,
                isEdit: true
              });
            }}
          >
            <StyledIcon name={"pencilLight"} size={"mediumLarge"} />
            {item.name}
            {showOverrideNumber && (
              <OverrideValueTag>{item.overrideValue}</OverrideValueTag>
            )}
          </OverrideTagButton>
          <DeleteButton
            data-cy={`delete-override-button`}
            disabled={!canUpdateROIValues}
            type={"closeIcon"}
            onClick={() => {
              deleteDiscoveryROIOverride({
                discoveryId,
                kpiCode: item.kpiCode,
                outcomeOverrideType
              });
            }}
            mobileOrTablet={mobileOrTablet}
          >
            <Icon name={"deleteLight"} size={"mediumLarge"} colour={"gray3"} />
          </DeleteButton>
        </ButtonWrapper>
      ))}
    </Container>
  ) : null;
};

const StyledIcon = styled(Icon)`
  margin-top: -4px;
  margin-right: 6px;
`;

const Container = styled.div`
  margin-top: 20px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: fit-content;

  &:hover button {
    opacity: 1;
  }
`;

const OverrideTagButton = styled(NewButton)`
  max-width: 800px;

  &:hover {
    color: ${themeProp("palette.secondary")};

    span {
      color: ${themeProp("palette.secondary")};
    }
  }
`;

const OverrideValueTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  padding: 0px 6px !important;
  border-radius: 6px;
  background-color: ${themeProp("palette.surface")};
  color: ${themeProp("palette.gray4")};
  font-size: ${themeProp("typography.tag.fontSize")};
  font-weight: ${themeProp("typography.tag.fontWeight")};
  line-height: ${themeProp("typography.tag.lineHeight")};
  font-family: ${themeProp("typography.tag.fontFamily")};
`;

const DeleteButton = styled(NewButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(30px, -50%);
  opacity: ${({ $mobileOrTablet }) => ($mobileOrTablet ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  background-color: transparent;
  width: 30px;

  &:hover {
    cursor: pointer;
  }
`;

ROIOverrides.propTypes = {
  annualAdjustmentMode: PropTypes.bool.isRequired,
  outcomeOverrideType: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired,
  showOverrideNumber: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  roiOverrides: selectDiscoveryRoiOverrides(state),
  discoveryKPIs: selectDiscoveryKPIs(state),
  canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
  deleteDiscoveryROIOverrideLoadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_DELETE_ROI_OVERRIDE_REQUEST
  ),
  deleteDiscoveryROIOverrideRequestParameters: selectRequestParameters(
    state,
    actionTypes.DISCOVERY_DELETE_ROI_OVERRIDE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDiscoveryROIOverride,
      startROIOverrideOperation,
      stopROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ROIOverrides
);
