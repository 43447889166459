import { bindActionCreators, compose } from "redux";
import {
  getDiscoverySummary,
  updateDiscovery
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../StringProvider";
import Form from "../Form";
import { DiscoveryAlert } from "./NewDiscoveryModal";
import PropTypes from "prop-types";
import { selectRequestError, selectRequestState } from "../../store/reducers";
import NewButton from "../NewButton";
import { useFeature } from "../FeaturesProvider";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import DiscoveryForm from "../Discovery/DiscoveryForm";
import SourceTypes from "../../utils/source-types";
import Modal from "../Modal";
import Notification from "../Notification";

const EditDiscoveryModal = ({
  discoveryId,
  accountId,
  accountName,
  accountSource,
  opportunityId,
  opportunityName,
  opportunitySource,
  version,
  currency,
  discoveryTypeCode,
  loadingState,
  error,
  onClose,
  updateDiscovery,
  getDiscoverySummary
}) => {
  const [form] = Form.useForm();
  const okText = s("edit_discovery.popup.button1", "Save");
  const cancelText = s("edit_discovery.popup.button2", "Cancel");
  const errorText = s(
    "edit_discovery.popup.messages.error",
    "Failed to save discovery"
  );
  const successText = s(
    "edit_discovery.popup.messages.success",
    "Discovery was edited"
  );
  const [isDuplicateError, setDuplicateError] = useState(false);
  const externalDiscoveryManagement = useFeature(
    "externalDiscoveryManagement",
    false
  );
  const hubspotIntegration = useFeature("hubspotIntegration", false);

  useEffect(() => {
    form.setFieldsValue({
      accountId,
      accountName,
      opportunityId,
      opportunityName,
      currency,
      version,
      discoveryTypeCode
    });
  }, [
    form,
    accountId,
    accountName,
    opportunityId,
    opportunityName,
    currency,
    version
  ]);

  useLoadingState(
    loadingState,
    () => {
      onClose();
      Notification.success(successText);
      getDiscoverySummary({ discoveryId });
    },
    () => {
      if (error) {
        if (error.status === 409) {
          setDuplicateError(true);
        } else {
          setDuplicateError(false);
          Notification.error(errorText);
        }
      } else {
        setDuplicateError(false);
        Notification.error(errorText);
      }
    }
  );

  const onFinish = async (values) => {
    updateDiscovery({ discoveryId, data: values });
  };

  return (
    <Modal
      title={s("edit_discovery.popup.header", "Discovery Details")}
      open={true}
      data-cy={"edit-discovery-modal"}
      onCancel={onClose}
    >
      {isDuplicateError ? <DiscoveryAlert /> : null}
      <DiscoveryForm
        isNameEditable={
          (!hubspotIntegration && !externalDiscoveryManagement) ||
          accountSource !== SourceTypes.CRM_MANAGED
        }
        isOpportunityEditable={
          (!hubspotIntegration && !externalDiscoveryManagement) ||
          opportunitySource !== SourceTypes.CRM_MANAGED
        }
        isCurrencyEditable={!externalDiscoveryManagement}
        accountSource={accountSource}
        opportunitySource={opportunitySource}
        form={form}
        okText={okText}
        onFinish={onFinish}
      >
        <NewButton type={"secondary"} onClick={onClose}>
          {cancelText}
        </NewButton>
      </DiscoveryForm>
    </Modal>
  );
};

EditDiscoveryModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  accountSource: PropTypes.oneOf(SourceTypes.ALL),
  opportunityId: PropTypes.string.isRequired,
  opportunityName: PropTypes.string.isRequired,
  opportunitySource: PropTypes.oneOf(SourceTypes.ALL),
  version: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  discoveryTypeCode: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.UPDATE_DISCOVERY_REQUEST),
  error: selectRequestError(state, actionTypes.UPDATE_DISCOVERY_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscovery,
      getDiscoverySummary
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditDiscoveryModal
);
