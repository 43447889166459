import { BasicBrandHeader, SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import React, { useEffect, useState } from "react";
import Form, { FormItem } from "../../components/Form";
import styled from "styled-components";
import { bindActionCreators, compose } from "redux";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addDiscovery } from "../../store/actions/discoveries";
import { useString as s } from "../../components/StringProvider";
import {
  selectAddDiscoveryError,
  selectAddDiscoveryLoadingState,
  selectAddDiscoveryResult,
  selectMasterDataVersion,
  selectRequestData,
  selectRequestState
} from "../../store/reducers";
import { setUserSettings } from "../../store/actions/user-settings";
import DiscoveryAlert from "../../components/DiscoveryOperations/NewDiscoveryModal/DiscoveryAlert";
import { getMasterData } from "../../store/actions/master-data";
import actionTypes from "../../store/actionTypes";
import { useTheme } from "../../components/ThemeProvider";
import useLoadingState from "../../utils/use-loading-state";
import NewButton from "../../components/NewButton";
import Heading from "../../components/Heading";
import TextBody from "../../components/TextBody";
import { selectLatestVersionNumber } from "../../store/selectors/versions";
import { getLatestVersion } from "../../store/actions/versions";
import DiscoveryForm from "../../components/Discovery/DiscoveryForm";
import SourceTypes from "../../utils/source-types";
import LoadingState from "../../utils/loading-state";
import Notification from "../../components/Notification";
import { getHubspotDeal } from "../../store/actions/hubspot";
import { useFeature } from "../../components/FeaturesProvider";
import { Alert, Icon } from "../../components";
import Input from "../../components/Input";

const CreateDiscovery = ({
  history,
  userSettings,
  newDiscovery,
  error,
  masterDataVersion,
  addDiscoveryLoadingState,
  addDiscovery,
  setUserSettings,
  getLatestVersion,
  latestVersionNumber,
  getMasterData,
  getHubspotDeal,
  hubspotLoadingState,
  deal
}) => {
  const hubspotIntegration = useFeature("hubspotIntegration");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [hubspotError, setHubspotError] = useState(false);
  const accountId = params.get("accountId");
  const [accountName, setAccountName] = useState(params.get("accountName"));
  const [opportunityId, setOpportunityId] = useState(
    params.get("opportunityId")
  );
  const [opportunityName, setOpportunityName] = useState(
    params.get("opportunityName")
  );
  const [opportunitySource, setOpportunitySource] = useState(
    opportunityId && opportunityName ? SourceTypes.CRM_MANAGED : undefined
  );
  const currency = params.get("currency");

  const [form] = Form.useForm();

  const defaultCoverImage = useTheme("assets.report_header_cover");
  const salesforceCoverImage = useTheme(
    "assets.salesforce_create_discovery_cover"
  );

  const okText = s("new_discovery.page.button1", "Start discovery");
  const header = s("new_discovery.page.header", "New Discovery");
  const subheader = s(
    "new_discovery.page.subheader",
    "Create the discovery by filling out the form"
  );
  const viewAllDiscoveries = s(
    "new_discovery.page.viewAllDiscoveries",
    "View all Discoveries"
  );
  const errorText = s(
    "new_discovery.page.error.text",
    "Failed to create discovery"
  );
  const hubspotErrorMessage = s(
    "errors.getHubspotDeal.errorMessage",
    "Hubspot Communication Error"
  );
  const hubspotErrorDescription = s(
    "errors.getHubspotDeal.hubspotFetchFailed",
    "The HubSpot integration is not correctly configured or has failed.  Please contact your administrator or support@cuvama.com"
  );
  const [isDuplicateError, setDuplicateError] = useState(false);

  useEffect(() => {
    getLatestVersion();
  }, []);

  useEffect(() => {
    if (latestVersionNumber && latestVersionNumber !== masterDataVersion) {
      getMasterData({ version: latestVersionNumber });
    }
  }, [latestVersionNumber, masterDataVersion]);

  useEffect(() => {
    if (
      hubspotIntegration &&
      opportunityId &&
      (!opportunityName || !accountName)
    ) {
      getHubspotDeal({ dealId: opportunityId });
    }
  }, [
    hubspotIntegration,
    opportunityId,
    opportunityName,
    accountId,
    accountName
  ]);

  useLoadingState(
    hubspotLoadingState,
    () => {
      setAccountName(deal.companyName || "");
      setOpportunityName(deal.dealName);
      setOpportunitySource(SourceTypes.CRM_MANAGED);
    },
    () => {
      setAccountName("");
      setOpportunityId("");
      setOpportunityName("");
      setOpportunitySource(SourceTypes.MANUALLY_CREATED);
      setHubspotError(true);
    }
  );

  useEffect(() => {
    form.setFieldsValue({
      accountId,
      accountName,
      opportunityId,
      opportunityName,
      opportunitySource,
      currency
    });
  }, [
    accountId,
    accountName,
    opportunityId,
    opportunityName,
    opportunitySource,
    currency
  ]);

  useEffect(() => {
    if (userSettings && userSettings.discoveryTypeCode) {
      form.setFieldsValue({
        discoveryTypeCode: userSettings.discoveryTypeCode
      });
    }
  }, [userSettings]);

  useLoadingState(
    addDiscoveryLoadingState,
    () => {
      setUserSettings({
        discoveryTypeCode: newDiscovery.discoveryTypeCode
      });

      history.push({
        pathname: `/discoveries/${newDiscovery["_id"]}/start-discovery`
      });
    },
    () => {
      if (error.status === 409) {
        setDuplicateError(true);
      } else {
        Notification.error(errorText);
        setDuplicateError(false);
      }
    }
  );

  useEffect(() => {
    if (userSettings && userSettings.discoveryTypeCode) {
      form.setFieldsValue({
        discoveryTypeCode: userSettings.discoveryTypeCode
      });
    }
  }, [userSettings]);

  const onFinish = (values) => {
    const data = { ...values, accountId, opportunityId };
    addDiscovery(data);
  };
  const onViewAllDiscoveries = () => {
    history.push({ pathname: "/MyDiscoveries" });
  };

  return (
    <Page header={<BasicBrandHeader />}>
      <SplitPageLayout
        image={salesforceCoverImage || defaultCoverImage}
        hasHeader={true}
      >
        {latestVersionNumber && latestVersionNumber === masterDataVersion ? (
          <Container>
            {hubspotError ? (
              <SubHeaderContainer>
                <Alert
                  data-cy={"hubspot-error"}
                  message={hubspotErrorMessage}
                  description={hubspotErrorDescription}
                  type={"error"}
                  icon={<Icon size={"larger"} name={"alert"} />}
                  showIcon
                />
              </SubHeaderContainer>
            ) : null}
            <Heading level={"h2"}>{header}</Heading>
            <SubHeaderContainer>
              <TextBody>{subheader}</TextBody>
            </SubHeaderContainer>

            {isDuplicateError ? <DiscoveryAlert /> : null}
            <DiscoveryForm
              form={form}
              okText={okText}
              isNameEditable={!accountName}
              isOpportunityEditable={!opportunityName}
              isCurrencyEditable={!currency}
              isDiscoveryTypeEditable={true}
              onFinish={onFinish}
              loading={addDiscoveryLoadingState === LoadingState.IN_PROGRESS}
            >
              <NewButton type={"secondary"} onClick={onViewAllDiscoveries}>
                {viewAllDiscoveries}
              </NewButton>
              {hubspotIntegration ? (
                <>
                  <FormItem hidden name="opportunityId">
                    <Input type="hidden" />
                  </FormItem>
                  <FormItem hidden name="opportunitySource">
                    <Input type="hidden" />
                  </FormItem>
                </>
              ) : null}
            </DiscoveryForm>
          </Container>
        ) : null}
      </SplitPageLayout>
    </Page>
  );
};

const Container = styled.div`
  padding: 40px;
`;

const SubHeaderContainer = styled.div`
  margin-bottom: 30px;
`;

const mapStateToProps = (state) => {
  return {
    userSettings: selectRequestData(
      state,
      actionTypes.GET_USER_SETTINGS_REQUEST
    ),
    newDiscovery: selectAddDiscoveryResult(state),
    error: selectAddDiscoveryError(state),
    masterDataVersion: selectMasterDataVersion(state),
    latestVersionNumber: selectLatestVersionNumber(state),
    addDiscoveryLoadingState: selectAddDiscoveryLoadingState(state),
    hubspotLoadingState: selectRequestState(
      state,
      actionTypes.GET_HUBSPOT_DEAL_REQUEST
    ),
    deal: selectRequestData(state, actionTypes.GET_HUBSPOT_DEAL_REQUEST)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDiscovery,
      setUserSettings,
      getMasterData,
      getLatestVersion,
      getHubspotDeal
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateDiscovery);
